/* CustomInput.css */
.custom-input {
    width: 459px;
    height: 60px;
    padding: 28px 26px;
    border-radius: 10px;
    color: #002300;
    border: 1px solid rgba(69, 69, 219, 0.3);
    opacity: 1; /* Make sure inputs are visible */
    box-shadow: none; /* Remove any box-shadow if applied */
    font-size: 18px; /* Optional: Set font size for the input text */
  }
  
  .custom-input::placeholder {
   
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #002300; /* Placeholder text color */
  }
  
  /* Focus styles */
  .custom-input:focus {
    outline: none; /* Remove default focus outline */
    border: 1px solid #4545DB4D; /* Keep border style consistent */
    box-shadow: none; /* Remove any focus box-shadow */
  }
  .helper-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  