.dashboard-container {
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.container {
    padding: 30px;
    border-radius: 10px;
}

.dropdown-toggle::after {
    display: none; /* This will remove the default dropdown arrow */
}

.nav-item {
    position: relative; /* Allows absolute positioning of dropdowns */
}

.dash-drop-menu {
    white-space: nowrap; /* Prevent wrapping */
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%; /* Positions dropdown below the parent */
    left: 0;
    background-color: #FFFFFF;
    border: 1px solid #F6F6F6;
    z-index: 1000;
    border-radius: 10px;
    min-width: 190px; /* Optional minimum width */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.dash-drop-menu-item {
    padding: 10px 15px;
    text-decoration: none;
    color: #002300;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: block;
    width: auto;
    font-size: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(230, 230, 230, 0.3);
    transition: none;
}

.dash-drop-menu-item:hover {
    background-color: transparent; /* Prevent background change */
    color: #002300; /* Maintain original text color */
    cursor: default; /* Maintain default cursor */
}

.form-container {
    width: 1328px;
    height: 461px;
}

.form-group {
    margin-bottom: 20px;
    /* Add spacing between form groups */
}

.btn {
    min-width: 120px;
    /* Ensure buttons have a minimum width */
}

.form-control-borderless {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-bottom: 0.5px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.5px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    box-shadow: none;
    outline: 0 none;

    cursor: auto;
}


.formcontainer .btn {
    transition: background-color 0.3s, color 0.3s;
}

.formcontainer .btn-primary:hover {
    background-color: #0056b3;
    color: white;
}

.input-group {
    flex-wrap: nowrap;
    border-radius: 50px;
    background-color: #f4f4f4;
}

.input-group .form-control {
    border: none;
    background-color: transparent;
    outline: none;  /* Removes the outline */

}

.table th {
    justify-content: center;
    height: 60px;
    background-color: #f9f9f9;
    font-weight: bold;
}

.table td {
    border-bottom: .5px solid #f4f4f4;
    text-align: left;
    vertical-align: middle;
}

.table tbody tr:hover {
    background-color: #f1f1f1;
}

.badge {
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    margin-left: 5px;
}

.pagination {
    border-end-start-radius: 24px;
    border-end-end-radius: 24px;
    padding: 10px;
    background-color: #f4f4f4;
    justify-content: end;
    align-items: end;
}

.pgbtn {
    border: #ced4da;
    width: 30px;
    height: 30px;
    border-radius: 30px;
}

.active-pgbtn {
    background-color: white; /* White background for active button */
    border: 1px solid #00230007;
    width: 30px;
    height: 30px;
    border-radius: 30px;
}

.filterbutton {
    background-color: #f4f4f4;
}

.dropdown-item {
    cursor: pointer;
    background-color: transparent; /* Ensure background is transparent */
}

.dropdown-item:hover {
    /* Remove hover effects */
    background-color: transparent !important; /* Override any other styles */
    color: inherit; /* Keep text color the same */
    text-decoration: none; /* Remove underline or other text effects */
}

.filtercontainer {
    display: flex;
    flex-wrap: wrap;
}

.btn.dropdown-vertical:focus,
.btn.dropdown-vertical:active {
  border: none;
  box-shadow: none;
}


.btn.dropdown-vertical:hover {
  border: none;
}


textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  box-shadow: none;
  outline: 0 none;
}

/* Media Queries */

/* For devices with screen width max 768px (tablets and mobile) */
@media (max-width: 768px) {
    .filtercontainer {
        display: none; /* Hide the filter buttons in mobile view */
    }

    .filterbutton {
        display: block; /* Show the filter button */
    }

    .dropdown-menu {
        width: 100% !important;
        position: static;
        margin-top: 0;
    }

    .dashboard-container {
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .form-container {
        width: 100%; /* Make form container responsive */
        height: auto; /* Allow height to adjust for smaller devices */
    }

    .table {
        font-size: 14px; /* Reduce table font size */
    }

    .pagination {
        display: flex;
        justify-content: center;
        padding: 8px; /* Adjust padding for smaller screens */
    }

    .pgbtn {
        width: 25px; /* Smaller pagination button */
        height: 25px;
    }

    .active-pgbtn {
        width: 25px;
        height: 25px;
    }

    .btn {
        min-width: 100px; /* Adjust button width for mobile */
    }
}

/* For devices with screen width max 820px (larger tablets) */
@media (max-width: 993px) {
    .filtercontainer {
        display: none; /* Hide filter buttons */
    }

    .filterbutton {
        display: block; /* Show the filter button */
    }

    .dropdown-menu {
        width: 100% !important;
        position: static;
        margin-top: 0;
    }

    .dashboard-container {
        padding: 20px;
    }

    .form-container {
        width: 100%; /* Adjust form container width */
        height: auto; /* Allow height to adjust */
    }

    .table th, .table td {
        padding: 8px; /* Reduce padding for better spacing */
    }

    .pagination {
        justify-content: center;
        padding: 8px;
    }

    .pgbtn {
        width: 25px;
        height: 25px;
    }

    .active-pgbtn {
        width: 25px;
        height: 25px;
    }
}
/* Assuming .searchcontainer is initially defined somewhere */
.flexiblesearch {
    width: 300px; /* or any other value as needed */
}

/* For mobile devices with screen width max 768px */
@media (max-width: 412px) {
    .flexiblesearch {
        width:.30%; /* Make it 30% smaller (i.e., 70% width) */
    }
}
