body {
  margin: 0px;
  padding: 0px;
}
.form-container {
  max-width: 1328px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 8px;
  overflow: auto;
}

@media (max-width: 768px) {
  .form-container {
    width: 100%;
    max-width: 100%;
  }
}



.custom-dropdown-menu {
  font-size: 22px;
  background-color: white !important;
  border: none;
  box-shadow: none;
}
.custom-dropdown-menu .dropdown-item {
  color: black;
  box-shadow: none;
  font-size: 16px;
  /* Set font size to 16px for dropdown items */
}
.custom-dropdown-menu .dropdown-item:hover {
  background-color: transparent !important;
  color: #000;
  box-shadow: none;
}
.btn-primary {

  width: 100px;
  background-color: #4545db !important;
  border: none;
  border-radius: 50px;
  box-shadow: none;
  outline: 0 none;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #4545db !important;
}
.btn-outline-secondary {
  box-shadow: none;
  outline: 0 none;
  border: none;
}
.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
  box-shadow: none;
  background-color: transparent;
  color: #000;
}
.dropdown,
.dropdown-toggle {
  background-color: transparent;
  border: none;
  color: #000;
  box-shadow: none;
  font-size: 22px;
}
.dropdown:hover,
.dropdown:focus,
.dropdown-toggle:hover,
.dropdown-toggle:focus {
  background-color: transparent;
  color: #000;
  border: none;
  box-shadow: none;
}
.header-container {
  width: 1600px;
  margin: 0 auto;
  opacity: 1;
}
.navbar {
  margin-top: 20px;
  width: 1600px;
  height: 90px;
  background-color: #FFFFFF;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(66, 71, 76, 0.08);
  align-items: center;
  box-sizing: border-box;
}
.navbar-nav {
  margin-left: auto;
}
.navbar .nav-item.active .nav-link {
  color: #4545db; /* Active link color */
  font-weight: bold; /* Bold text for active link */
}
.nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-item {
  margin: 0 15px;
  position: relative;
  display: flex;
  align-items: center;
}
.nav-link {
  font-family: "Manrope";
  font-size: 18px !important;
  color: #002300;
  text-decoration: none;
}
.nav-link:hover {
  color: #005600;
}
/* Logo styles */
.logo {
  width:95px;
  height: 70px;
}
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 10px;
  background-color: #ffffff;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.dropdown-menu li {
  padding: 10px 15px;
  align-items: center;
}
.dropdown-menu li a {
  color: #002300;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.avatar-dropdown-menu .dropdown-item {
  background-color: transparent !important;
  color: #002300;
}
.avatar-dropdown-menu .dropdown-item:hover {
  background-color: transparent !important;
  color: #005600;
}
.avatar-container .avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.dropdown-menu li a:hover {
  background-color: #4545db;
}
.dropdown-menu li i {
  margin-right: 10px;
}
.nav-item:hover .dropdown-menu {
  display: block;
}
.trail {
  width: 170px;
  height: 40px;
  /* background-color: #e8bbbb; */
   color:#fff;
  border-radius: 10px;
  display: flex;
  /* border:1px solid #4545db; */
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.trail p {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.notification-container {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.avatar-container {
  display: flex;
  align-items: center;
  position: relative;
}
.avatar {

  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.alignright {
  padding: 10px;
  display: flex;
  align-items: center;
}
.avatar-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.avatar-dropdown-menu li {
  padding: 8px 12px;
}
.avatar-dropdown-menu li a {
  text-decoration: none;
  color: #333;
}
.avatar-dropdown-menu li:hover {
  background-color: #f1f1f1;
}
.notification-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
}
.notification-dropdown-menu li {
  padding: 10px;
}
.notification-dropdown-menu li a {
  text-decoration: none;
  color: #333;
  display: block;
}
.notification-dropdown-menu li a:hover {
  background-color: #f5f5f5;
}
/*----------------------------------------------------------------------------------setting dropdown-----------------------------------------------------------------*/
.nav-item {
  position: relative; /* Allows absolute positioning of dropdowns */
}
.nav-menu-drop {
  white-space: nowrap; /* Prevent wrapping */
}

.nav-menu-drop {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%; /* Positions dropdown below the parent */
  left: 0;
  background-color: #FFFFFF;
  border: 1px solid #F6F6F6;
  z-index: 1000;
  border-radius: 10px;
  min-width: 120px; /* Optional minimum width */
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

.nav-menu-drop-item {
  padding: 10px 15px;
  text-decoration: none;
  color: #002300;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: block;
  width: auto;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(230, 230, 230, 0.3);
  transition: none;
}
.nav-menu-drop-item:hover {
  background-color: transparent; /* Prevent background change */
  color: #002300; /* Maintain original text color */
  cursor: default; /* Maintain default cursor */
}

/* Responsive adjustments */
@media (max-width: 1600px) {
  .header-container,
  .navbar {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    height: auto;
  }
  .nav-list {
    flex-direction: column;
    align-items: flex-start;
  }
  .nav-item {
    margin: 10px 0;
  }
}
/* Form Row and Column css With Table Css */
.dynamic-form-element {
  display: block;
  margin-bottom: 10px;
  width: 100%;
}
/* Grid system */
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.dynamic-form-element {
  margin-bottom: 10px;
}
.table-container {
  border-radius: 20px;
  padding: 20px;
  background-color: white;
  width: auto;
}
.table-darkcustom th {
  vertical-align: middle;
  background-color: #f4f4f4;
  color: #000000;
  height: 60px;
  text-align: start;
  font-size: 18px;
  width: 1248px;
}
.table-darkcustom td {
  text-align: start;
  vertical-align: middle;
  font-size: 16px;
}
/* Styling for the last header cell */
.table-darkcustom th.last-header {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.tables > :not(:last-child) > :last-child > * {
  border-bottom-color: transparent;
}
.table-darkcustom th {
  border: none;
  height: 60px;
}
.table-darkcustom th:last-child {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}
.table-darkcustom th:first-child {
 padding-left: 20px;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}
.table-darkcustom th:first-child {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}
.tdclass {
  border-bottom: 0.5px solid #f4f4f4;
}
.tables {
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
  border-top: none;
}
/* ==================================================================================================Search Container ============================================================ */
/* .filter-dropdown-btn{
  margin: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-radius: 60px;
  background-color: #F4F4F4;
} */
.search-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}


.position-relative {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 15px; /* Adjust as needed */
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicks on the icon */
}

.search-container {
  position: relative;
  flex: 3;
  max-width: 300px;
}
.search-container input {
  width: 100%;
  height: 50px;
  background: #f4f4f4;
  border-radius: 50px;
  border: none;
  padding-left: 40px;
  font-size: 16px;
  box-sizing: border-box;
}
.search-container input::placeholder {
  color: #a9a9a9;
}
.search-container input:focus {
  outline: none;
}
.search-container-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
/* ======================================================================================Search Container================================== */
.filter-dropdown-btn {
  box-shadow: none;
  outline: 0 none;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  /* Center vertically */
}
.filter-dropdown-btn svg {
  margin-right: 8px;
  /* Space between icon and text */
}
.filter-dropdown-btn h4 {
  margin: 0;
  /* Remove default margin */
  text-align: center;
  /* Center the text */
  flex-grow: 1;
  /* Allow the text to take available space */
}
/* ======================================================================================Filter Start =========================================================================== */
.filter-container {
  justify-content: center;
  width: auto;
  height: 50px;
  background: #f4f4f4;
  border-radius: 50px;
  border: none;
  padding-left: 20px;
  margin-left: 20px;
  align-items: center;
  height: 50px;
  display: flex;
  background-color: #f0f0f0;
  border-radius: 50px;
  margin: 10px;
  gap: 15px;
}
.filterdropdown-container {
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  width: 294px;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.filter-container2 {
  position: relative;
  width: 130px; /* Set width to 130 pixels */
  height: 50px;
  display: flex;
  background-color: #f0f0f0;
  margin-left: 30px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
}
.dropdown-menu2 {
  margin-bottom: 10px;
}
.custom-select-form {
  padding: 10px;
  width: 260px;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  height: 44px;
}
.from-btn {
  box-shadow: none;
  outline: 0 none;
  display: flex;
  justify-content: space-evenly;
  width: 144px;
  padding: 10px;
  background-color: transparent;
  border: 1px solid #d9d9d9; /* Add border with desired color */
  border-radius: 30px;
  cursor: pointer;
}

.btn-container svg {
  margin-left: 10px; /* Optional: Add space between text and icon */
}
.apply-filter-button {
  border: none;
  display: flex;
  justify-content: center;
  background-color: #4545db;
  height: 42px;
  width: 163px;
  color: #ffff;
  margin-top: 10px;
  border-radius: 60px;
  align-items: center;
}
.from-to {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-check-input:checked {
  width: 18px;
  height: 18px;
  background-color: #4545db;
  border-color: #4545db;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.filter-button {
  background-color: transparent;
  border: none;
  height: 40px;
  padding: 3px 3px;
  /* Top and bottom, left and right */
  padding-left: 15px;
  /* Add left padding */
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: center;
}
.filter-button:hover {
  background-color: #e0e0e0;
}
.filter-button.active {
  background-color: white;
}
.count {
  margin: 5px;
  width: 30px;
  height: 30px;
  background-color: #5045e5;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.filter-button:nth-child(1) .count {
  background-color: #4caf50; /* Green */
}
.filter-button:nth-child(2) .count {
  background-color: #e91e63; /* Blue */
}
.filter-button:nth-child(3) .count {
  background-color: #d1d1ef; /* Amber */
}
.filter-button:nth-child(4) .count {
  background-color: #e91e63; /* Pink */
}
.filter-button:nth-child(5) .count {
  background-color: #9c27b0; /* Purple */
}
.filter-button:nth-child(n + 6) .count {
  background-color: #5045e5; /* Original color */
}
/* ======================================================================Filterbtn======================================================================================== */




/* =============================================Expense Screen CSS Start ======================= */
.expensecontainer {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: auto;
  background-color: white;
  width: 90%;
  /* Changed from 1600px to a percentage */
  max-width: 1600px;
  /* Maximum width for larger screens */
  height: auto;
  /* Change to auto for flexible height */
  border: 1px solid #dee2e6;
  padding: 30px;
  justify-content: start;
}
h3 {
  font-weight: 800;
  margin: 0;
  font-size: 22px;
  color: #343a40;
}
.cus {
  box-shadow: none;
  outline: none;
  width: auto;
  height: 42px;
  padding: 8px 12px;
  font-size: 16px;
  border: none;
  border-radius: 60px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}
.expense-searchcontainerstart {
  width: 90%;
}
.custbtn {
  box-shadow: none;
  outline: 0 none;
  border-radius: 40px;
  margin-left: 10px;
  margin-top: 3px;
  width: auto;
  /* Change to auto for responsiveness */
  height: 45px;
  color: #4545db;
  border: 1px solid #4545db;
  background-color: transparent;
  transition: color 0.3s, background-color 0.3s;
}
.custbtn2 {
  box-shadow: none;
  outline: 0 none;
  color: #f0f0f0;
  background-color: #4545db;
  margin-left: 10px;
  width: auto;
  /* Change to auto for responsiveness */
  height: 48px;
  border-radius: 10px;
}
.custbtn2:hover {
  color: #f0f0f0;
  background-color: #4545db;
}
.custbtn:hover {
  color: #4545db;
  background-color: transparent;
  border: 1px solid #4545db;
}

.selectoptions {
  margin-left: 10px;
  border-radius: 10px;
  width: auto;
  /* Change to auto for responsiveness */
  height: 50px;
  border: transparent;
  background-color: #f4f4f4;
  text-align: center;
}
.scontainerdropdownd {
  justify-items: center;
  align-items: center;
  display: flex;
}



.table-responsive th {
  background-color: #f4f4f4;
}
.table-responsives {
  border: 0.5px solid #f4f4f4;
  border-radius: 24px 24px 0 0; /* top-left and top-right corners to 24px */
}
.table-responsive {

  border-radius: 24px 24px 0 0; /* top-left and top-right corners to 24px */
}
.expensetableheader th:first-child {
  border-top-left-radius: 24px;
  /* Adjust as needed */
}
.expensetableheader th:last-child {
  border-top-right-radius: 24px;
  /* Adjust as needed */
}
/* Responsive Styles */
@media (max-width: 768px) {
  .expensecontainer {
    padding: 15px;
    width: 95%;
  }
  .expensetableheader th {
    font-size: 14px;
    /* Adjust font size for headers */
  }

}
.totalcalculation {
  border-top: none;
}
.tdnexp {
  border-right: 0.5px solid #f4f4f4;
  border-bottom: 0.5px solid #f4f4f4;
  border-left: 0.5px solid #f4f4f4;
}
.totalcont {
  align-items: end;
  justify-content: end;
  border-color: transparent;
}
h5 {
  text-align: end;
  align-items: end;
  justify-items: end;
  font-size: 18px;
}
.semibold {
  font-weight: 600;
}
.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-container {
  max-width: 768px; /* Updated to 768px */
  margin: 0;
  background-color: white;
  border-radius: 0.5rem;
}
.modal-header,
.modal-body,
.modal-footer {
  padding: 1rem;
}
.modal-close-btn {
  cursor: pointer;
}
.modal-save-btn {
  box-shadow: none;
  outline: 0 none;
  background-color: #007bff;
  color: white;
}


/*    store drop down style  */

.store-menu {
  position: relative;
}

.dropdown-container {
  position: absolute;
  top: 60%;
  right: 100%;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px;
  transform: translateX(-10px);
}

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-item {
  margin: 5px 0;
  border-bottom: 1px solid rgba(230, 230, 230, 0.3);
}

.dropdown-link {
  text-decoration: none;
  color: #333;
  padding: 5px 10px;
  display: block;
  overflow: hidden;
  white-space: wrap;
  /* text-overflow: ellipsis;  */
}

.dropdown-link:hover {
  background-color: #f0f0f0;
}



/* =============================================Expense Screen CSS End ======================= */

  /* Slim scrollbar */
  div::-webkit-scrollbar {
    width: 8px; /* Slim width */

  }

  /* Track */
  div::-webkit-scrollbar-track {
    background-color: #d6ebff;
  }

  /* Handle */
  div::-webkit-scrollbar-thumb {
    background: #5c9ad8; /* Darker handle */
    border-radius: 10px; /* Rounded handle */
  }

  /* Handle on hover */
  div::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker on hover */
  }


  .printBtn{
    opacity: 0.3;
  }


  .printBtn:hover{
    opacity: 1;
  }