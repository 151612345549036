.modal {
  position: fixed; /* Fix modal in viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Optional background overlay */
}

.modal-data {
  position: relative; 
  background-color: white; 
  border-radius: 8px;
  padding: 20px;
  width: 80%; 
  max-height: 90%; 
  overflow-y: auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.modal-header {
  text-align: center; /* Center title */
  margin-bottom: 20px; /* Space below title */
}

.close-button {
  background-color: #4545db;
  color: white;
  border: none;
  padding: 10px 45px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 24px; 
  right: 20px; 
}



.reports-table-title {
  font-size: 22px;
  color: #002300;
}

.lotterys-title-header {
  background-color: #f4f4f4;
  height: 55px;
  border-radius: 25px 25px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.lotterys-report-title {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.lotterys-report {
  margin-top: 0px;
  border: 1px solid #f6f6f6;
}

.data-reports {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-top: 10px;
  border: 1px solid #e6e6e6;
}

.report-table-title {
  font-size: 22px;
  color: #002300;
}
