/*================================================Setting page===================================================================================*/
.setting-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto;
  background-color: #ffffff;
  width: 1600px;  
  max-width: 100%;
}
.setting-title {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 22px;
  color: #002300;
  align-self: flex-start;
}
.row.setting-manage {
  width: 1600px;
  max-width: 100%;
}

.card {
  margin-bottom: 20px;
  max-width: 100%;
}

/* Apply for all cards */
/* Default card-body styles */
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 760px;
  max-width: 100%;
  height: 56px;
  transition: height 0.3s ease-in-out;
}

/* Apply red border only for IDs 13 & 14 */
.card-body.auto-height-13,
.card-body.auto-height-14 {
  height: auto;
}

/* Apply red border **only** between the question and the dropdown for 13 & 14 */
.card-body.auto-height-13 .question-switch-container,
.card-body.auto-height-14 .question-switch-container {
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 10px; /* Add spacing for better visibility */
}

/* Remove border from other card-body elements */
.card-body:not(.auto-height-13):not(.auto-height-14) .question-switch-container {
  border-bottom: none;
}



.question-switch-container {
  display: flex;
  justify-content: space-between;

  /* align-items: center; */
  width: 100%;
}

/* Apply auto height when dropdown is active */


/* Specific styles for IDs 13 and 14 */
.custom-card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: flex-start; /* Align content to the left */
  height: auto; /* Dynamically adjust height */


}

/* Container for question text and switch on the same row */
/* Default: Question and switch in a row */
/* .question-switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
} */

/* For settings with dropdown (IDs 13 & 14), stack content vertically */
.custom-card-body .question-switch-container {
  flex-direction: row;
  align-items: flex-start; /* Align left */

}


/* Styles for question text */
.question-text {
 
  font-size: 16px;
  color: #002300;
  margin-right: 10px; /* Add space between question text and switch */

}

/* Style for switch */
/* .switch {
  display: inline-block;
} */

/* Style for dropdown */
.card01 {
  width: 100%;
  height: auto;
  margin-top: 10px; /* Ensure there's spacing between the switch and dropdown */
}



.form-select {
  margin-top: 5px;
  width: 40%;
  background-color: #f6f6f6;
}

.form-check {
  margin: 0;
}
.card {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
}
.form-check-input {
  cursor: pointer;
}



/*===========================================================================Bank container=================================================================*/
.bank-container {
  padding: 20px; /* Adjust as needed */
  margin: 30px auto;
  background-color: #ffffff;
  border-radius: 10px;
  width: 1600px;
  max-width: 100%;
}
.header-row {
  display: flex;
  justify-content: space-between;
  width: 1540px;
  max-width: 100%;
}
.setting-title {
  font-size: 24px; /* Adjust font size as needed */
  margin: 0; /* Remove default margin */
}
.bank-add-button {
  padding: 8px 16px;
  background-color: #4545db; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  width: 155px;
  height: 42px;
  font-size: 18px;
}
.second-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.bank-cards {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
}
.bank-card {
  width: 426px;
  height: 206px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 35, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  padding: 16px;
  margin: 10px 0; /* Vertical margin only */
  padding: 16px;
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
}
.bank-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.bank-details {
  display: flex;
  flex-direction: column;
}
.bank-name-group {
  margin-bottom: 16px; /* Space between the groups of names */
}
.bank-name {
  font-weight: bold;
  font-size: 18px;
  color: #002300;
  margin-bottom: 2px;
}
.bank-short-name {
  font-size: 16px;
  color: #002300;
  margin: 0;
}
.del-icon {
  color: #002300;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.card-button {
  text-align: left;
  border: 1px solid #4545db;
  border-radius: 40px;
  margin-top: 10px;
  background-color: #ffffff;
  height: 42px;
  padding-left: 25px;
  font-size: 18px;
  color: #4545db;
}
.card-button.default {
  width: 212px;
}
.card-button.atm {
  width: 252px;
}
.card-button.ltry {
  width: 277px;
}
.card-button.credit {
  width: 313px;
}
/*======================================================Due Days css Start==========================================================================*/
.due-days {
  padding: 20px; /* Adjust as needed */
  margin: 20px auto;
  background-color: #ffffff;
  border-radius: 10px;
  width: 1600px;
  max-width: 100%;
}
.due-card {
  position: relative;
  width: 360px; /* Fixed width for each card */
  height: 185px; /* Fixed height for each card */
  background-color: #ffffff;
  border: 1px solid rgba(0, 35, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column; /* Column layout inside the card */
  padding: 16px;
  margin: 10px; /* Margin for spacing */
}
.due-details {
  display: flex;
  flex-direction: column;
}
.due-icon {
  display: flex;
  align-items: center;
  gap: 15px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.input-day {
  width: 260px;
  height: 44px;
  background-color: #f6f6f6;
  border: 1px solid rgba(0, 35, 0, 0.1);
  border-radius: 4px;
}
.card-button.day {
  margin-top: 20px;
  width: 191px;
}
.btn-position {
  display: flex;
  justify-content: flex-end;
}
.vendor-add {
  flex-direction: column;
  margin-bottom: 10px; /* Space between rows */
}
.vendor-add-button {
  padding: 8px 26px;
  background-color: #4545db; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  height: 42px;
  font-size: 18px;
  margin-left: 10px;
  margin-bottom: 10px; /* Space between buttons */
}
/* Remove the margin from the last button */
.vendor-add-button:last-child {
  margin-bottom: 0; /* No margin for the last button */
}
/* Mobile responsiveness */
@media (max-width: 600px) {
  .vendor-add-button {
    width: 100%; /* Full width buttons */
    margin-bottom: 10px; /* Maintain spacing for mobile */
  }
}
/* styles.css */
.rounded-table {
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #ccc;
}
.table-head {
  background-color: #f4f4f4;
}
.table-head th {
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
}
.table-body td {
  font-size: 16px;
}
/*===============================================================Store Css Start===============================================================*/
.store-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; /* Align items to the start (left) */
  border-radius: 10px;
  padding: 30px;
  margin: 20px auto;
  background-color: #ffffff;
  width: 1600px;
  max-width: 100%;
}
.setting-title {
  margin-bottom: 10px; /* Space between title and inputs */
  font-size: 24px; /* Adjust as needed */
}
.input-container {
  width: 100%; /* Ensure it takes the full width */
}
.input-row {
  display: flex; /* Use flexbox to align input fields */
  gap: 10px; /* Space between input fields */
}
.input-field {
  padding: 10px;
  width: 600px;
  height: 40px;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
}
.card-container {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.plan-title {
  align-items: center; /* This centers the items vertically */
  gap: 10px;
  width: 308px;
  height: 46px;
  font-size: 20px;
  justify-content: center; /* Center items horizontally */
}
.current-plan {
  font-weight: 600;
  font-size: 20px;
  margin-left: 5px;
  text-align: center;
  color: #000;
  width: 132px;
  background-color: #ececfb;
  justify-content: center;
  align-items: center;
}
.card-store {
  width: 377px; /* Set card width */
  height: 400px; /* Set card height */
  border: 1px solid rgba(69, 69, 219, 0.3);
  box-shadow: 12px 4px 4px rgba(0, 0, 0, 0.18);
  border-radius: 20px;
  position: relative;
}
.card-store.basic {
  background-color: #f4f6ff;
}
.card-store.premium {
  background-color: #ececfb;
}
.card-store.enterprise {
  background-color: #f4f6ff;
}
.card-store-title {
  width: 313px;
  font-size: 32px;
  margin-top: 20px;
  margin-left: 20px;
  font-weight: 600px;
}
.card-store-description {
  width: 325px;
  font-size: 20px;
  margin-left: 20px;
}
.card-store-sub-amount {
  display: flex;
  align-items: center;
  width: 313px;
  font-size: 88px;
  margin-left: 20px;
  font-weight: 600px;
}
.dollar-sign {
  font-size: 40px;
  margin-right: 8px;
}
.card-store-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; /* Center horizontally */
  border: 1px solid #4545db;
  border-radius: 40px;
  margin-top: 10px;
  background-color: #ffffff;
  height: 52px;
  width: 300px; /* Adjust width */
  padding: 0 25px;
  font-size: 18px;
  color: #4545db;
  cursor: pointer;
}
.card-store-button.premium {
  background-color: #4545db;
  color: #ffffff;
}
.sub-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}
/*-----------------------------------------------------upcoming invoice-------------------------------------*/
.saved-card {
  width: 260px;
  height: 165px;
  background-color: #f0f0f0;
  border: 1px solid rgba(0, 35, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
  margin: 0 10px;
}
.card-head {
  display: flex;
  justify-content: space-between; /* Align label and bank name */
  align-items: center; /* Center vertically */
  margin-bottom: 10px; /* Space below header */
}
.credit-card-label {
  font-size: 16px;
  margin: 0;
}
.bank-name-card {
  font-size: 16px;
  text-align: right; /* Align bank name to the right */
  margin: 0; /* Remove default margin */
}
.card-number {
  text-align: center;
  font-size: 20px; /* Adjust size as needed */
  margin-top: 20px;
  font-weight: 500;
}
.card-bottom {
  display: flex;
  justify-content: space-between; /* Space between card holder and validity */
  margin-top: auto; /* Push footer to the bottom */
}
.card-holder {
  font-size: 16px;
  text-align: left;
  margin: 0; /* Remove default margin */
}
.valid-through {
  font-size: 12px;
  text-align: right;
  margin: 0;
}
.dept-button {
  padding: 6px 8px;
  background-color: #4545db; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  width: 109px;
  height: 42px;
  font-size: 18px;
}
.dept-cards-row {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap; /* Allow wrapping to the next row */
  gap: 10px; /* Space between cards */ /* space above cards */
}
.dept-card {
  width: 474px; /* fixed width */
  height: 70px; /* fixed height */
  padding: 10px;
  border: 1px solid #f6f6f6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dept-info {
  width: 386px;
  height: 50px;
  padding: 10px;
  background-color: #f6f6f6;
  border: 1px solid rgba(0, 35, 0, 0.1);
  border-radius: 4px;
}
.create-department-section {
  width: 100%;
}
.dept-icon {
  cursor: pointer;
}
/* ===========================================================Expense Screen CSS Start =============================================================*/
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2V5" stroke="%23002300" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 2V5" stroke="%23002300" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.5 9.08984H20.5" stroke="%23002300" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="%23002300" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.6947 13.6992H15.7037" stroke="%23002300" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.6947 16.6992H15.7037" stroke="%23002300" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.9955 13.6992H12.0045" stroke="%23002300" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.9955 16.6992H12.0045" stroke="%23002300" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.29431 13.6992H8.30329" stroke="%23002300" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.29431 16.6992H8.30329" stroke="%23002300" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}
input[type="date"] {
  cursor: default;
}
/*==================================================================vendor multiselect==========================================================*/
.chips-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px; /* Space between chips and dropdown */
  max-width: 483px; /* Match the dropdown width */
}

.chip {
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.chip-close {
  margin-left: 5px;
  cursor: pointer;
}
/* Custom styles for the select element and option hover */


.data-table-container {
  overflow-x: auto;
  max-width: 100%;
  width: 100%;
}
.data-table {
  width: 100%;
  border: 1px solid #F4F4F4;
  border-collapse: collapse;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}
.table-header th, .table-body td {
  border: 1px solid #F4F4F4;
  padding: 12px;
  text-align: left;
  padding-left: 20px;
}
.table-header th {
  background-color: #f2f2f2;
  color: #002300;
  cursor: pointer; /* Added cursor for sortable columns */
}
.table-body td {
  padding-left: 20px;
  color: #002300;
}
.table-body tr:last-child td {
  border-bottom: 1px solid #F4F4F4;
}
.table-body {
  border-bottom: 1px solid #F4F4F4;
  border-left: 2px solid #F4F4F4;
  border-right: 2px solid #F4F4F4;

}
.no-column {
  width: 100px;
}
.action-column {
  color: #002300;
  width: auto;
  text-align: right;
}
.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 5px;
}
.action-button svg {
  width: 16px;
  height: 16px;
  fill: #002300;
  transition: fill 0.3s;
}
.action-button:hover svg {
  fill: #005600;
}
.pagination {
  display: flex;
  justify-content: end;
  padding: 10px 0;
}
.pagination .page-item {
  margin: 0 5px;
}
.pagination .page-link {
  padding: 10px;
  border: 1px solid #F4F4F4;
  border-radius: 50%;
  color: #002300;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  transition: background-color 0.3s, color 0.3s;
}
.pagination .page-link:hover {
  background-color: #f2f2f2;
}
.pagination .page-item.active .page-link {
  background-color: #FFFFFF;
  color: #002300;
  border-color:#fbfbfbfb;
  border: 1px solid #d3d3d3fb;
}
.pagination .page-item.disabled .page-link {
  opacity: 0.5;
  pointer-events: none;
}
.pagination .prev-next {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #F4F4F4;
  color: #002300;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.pagination .prev-next:hover {
  background-color: #f2f2f2;
}

