.gas-invoice-add-button-bank-deposit{
    padding: 8px 16px;
    background-color: #4545db; /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    width: 221px;
    height: 42px;
    font-size: 18px;
}
.modal-title{
    font-size: 22px;
    font-weight: 600;
    color: #000;
}