.lottery-table-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  margin: 30px auto;
  background-color: #ffffff;
  width: 1600px;
  max-width: 100%;
}
.lottery-weekly-add-button {
  padding: 8px 16px;
  background-color: #4545db; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  width: 344px;
  height: 42px;
  font-size: 18px;
}
.lottery-book-settle-button {
  padding: 8px 16px;
  background-color: #4545db; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  width: 324px;
  height: 42px;
  font-size: 18px;
}
/*========================================Manage Game=========================================================================*/
.lottery-game-add-button {
  padding: 8px 16px;
  background-color: #4545db; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  width: 204px;
  height: 42px;
  font-size: 18px;
}
/*==========================================================  lottry popupcard=========================================*/
.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-field {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box; /* Ensures padding is included in total width */
}
/*=========================lotteryinventorytablecss*/
/* styles.css */
.rounded-table {
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #ccc;
}
.table-head {
  background-color: #f4f4f4;
}
.table-head th {
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
}
.table-body td {
  font-size: 16px;
  padding: 10px;
}
/*============================================scanned data=========================================================================*/
.lottery-game-details {
  padding: 15px;
  border: 2px solid #f4f4f4;
}

.game-details-row {
  display: flex;
  align-items: center;
  gap: 8px; /* Reduced space between elements */
  flex-wrap: wrap; /* Allow elements to wrap to the next line on smaller screens */
}

.game-details-cell {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; /* Adds spacing between rows */
  width: 12%; /* Adjust width for normal screens */
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .game-details-cell {
    width: 18%; /* Increase width on medium screens */
  }
}

@media (max-width: 992px) {
  .game-details-row {
    gap: 5px; /* Reduce space between elements */
  }

  .game-details-cell {
    width: 22%; /* Increase width for smaller devices */
  }
}

@media (max-width: 768px) {
  .game-details-row {
    flex-direction: column; /* Stack the form inputs vertically */
    align-items: flex-start; /* Align items to the left */
    gap: 12px; /* Add more space between rows */
  }

  .game-details-cell {
    width: 100%; /* Make each input take full width */
  }

  .input-field,
  .input-game {
    width: 100%; /* Inputs take full width */
  }
 
  .btn-activate {
    width: 100%; /* Make the button take full width */
    margin-left: 0; /* Remove the left margin */
  }
}

@media (max-width: 480px) {
  .game-details-row {
    gap: 8px; /* Adjust the gap for small screens */
  }

  .game-details-cell {
    width: 100%; /* Stack items vertically and take full width */
  }

  .input-field,
  .input-game {
    width: 100%; /* Inputs will fill the available space */
  }

  .btn-activate {
    width: 100%; /* Button takes full width on small screens */
  }
}
