.phone-input-container {
  display: flex;
  flex-direction: column;
  width: 459px; /* Adjust container width if needed */
  margin: auto;
}

.phone-input {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.phone-input-inner {
  position: relative;
  width: 100%;
}

.custom-input {
  width: 100%;
  height: 60px;
  padding: 28px 26px;
  border-radius: 10px;
  border: 1px solid #4545db4d; /* Your border color */
  opacity: 1; /* Ensure inputs are visible */
  box-shadow: none; /* Remove any box-shadow if applied */
  font-size: 16px; /* Optional: Set font size for the input text */
  padding-right: 40px; /* Added padding to the right to leave space for the icon */
}

.custom-input:focus {
  outline: none;
  border: 1px solid #4545db4d;
}

.input-error {
  border: 1px solid red; /* Highlight the input border in red */
}

.error-message {
  color: red; /* Red color for error text */
  margin-top: 4px;
  font-size: 12px;
}

/* Custom styling for the SVG down arrow */
.down-arrow {
  position: absolute;
  right: 10px; /* Position the icon on the right side */
  top: 50%;
  transform: translateY(-50%); /* Vertically center the arrow */
  cursor: pointer;
}

.custom-input::placeholder {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
}

.custom-country-select {
  border: 1px solid #4545db4d;
  border-radius: 10px 10px 0 0;
}
