.custom-background {
  width: 827px;
  height: 860px;
  top: 135px;
  left: 833px;
  gap: 0px;
  border-radius: 0px 30px 30px 0px;
  opacity: 1; /* Set opacity to 1 to make it visible */
  position: absolute;
  z-index: 1; /* Ensure it's on top of other content */
}

/* Hide the background on smaller screens */
@media (max-width: 768px) {
  .custom-background {
    display: none;
  }

 
}
