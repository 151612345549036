.report-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  margin: 30px auto;
  background-color: #ffffff;
  width: 1600px;
}
.tab-view {
  align-self: flex-start;
}
.nav-tabs {
  margin-bottom: 20px;
}
.nav-item {
  margin-right: 10px;
}
.nav-link {
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  text-decoration: none;
  color: #002300;
  font-size: 22px;
}
.nav-link.active {
  color: #002300;
  font-weight: 600; /* Change to semibold */
  font-size: 22px;
  border-bottom: 3px solid #4545db; /* Add border at the bottom */
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #f8f9fa;
  border-color: #ddd;
}
.tab-content {
  margin-left: 10px;
}
/* .business-report-title {
  cursor: pointer;
  transition: color 0.3s ease;
} */

.active-tab {
  font-weight: bold;
  color: black; /* or any primary color you like */
}

.disabled-tab {
  color: gray;
  cursor: default;
}



/*===================================================================Business Report============================================================*/
.business-header {
  background-color: #ffffff;
  border-radius: 10px;
  width: 1540px;

}
.business-title {
  display: flex;
  font-weight: bold;
  font-size: 22px;
  color: #002300;
  align-self: flex-start;
}
.btn-position {
  display: flex;
  justify-content: flex-end;
}
.business-button {
  padding: 8px 26px;
  background-color: #f4f4f4; /* Bootstrap primary color */
  color: #002300;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  height: 44px;
  font-size: 18px;
  margin-left: 10px;
}
.business-button.del {
  color: #f4f4f4;
  background-color: #b71d35;
}
.dropdown {
  position: relative;
}
.dropdown-toggle.shift {
  align-items: center;
  width: 142px;
  height: 44px;
  border-radius: 6px;
  background-color: #f4f4f4;

}
.dropdown-menu.shift {
  align-items: center;
  padding: 10px;
  justify-content: center;
  position: absolute;
  border-radius: 10px;
  border: 1px solid #f4f4f4;
  margin-top: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.dropdown-item.shift {
  padding: 5px;
  font-size: 15.84;
  font-weight: 500;
}
.shift-text {
  font-size: 15.84px;
  font-weight: 500;
}
.business-title-header {
  background-color: #f4f4f4;
  height: 55px;
  border-radius: 25px 35px 0px 0px;
}
.business-report-title {
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
}
.business-report {
  margin-top: 0px;
  border: 1px solid #f6f6f6;
}
.data-report {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}
.label {
  font-size: 18px;
  color: #002300;
}
.input {
  background-color: #F6F6F6;
  padding: 5px;
  border: 1px solid rgba(0, 35, 0, 0.1);
  border-radius: 4px;
  margin-left: 0px;
  width: 157px;
  outline-style: none;
}
.input-readonly {
  background-color: #F4F4F470;
  padding: 5px;
  border: none;
  border-radius: 4px;
  margin-left: 0px;
  width: 157px;
  outline-style: none;
}
.data-select {
  padding: 5px;
  width: 289px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #f6f6f6;
  outline-style: none;
}
.notes-area {
  width: 437px;
  height: 228px;
}
.report-table-title {
  font-size: 22px;
  color: #002300;
}
.input-lottery-container {
  position: relative;
  width: 100%; /* Adjust as needed */
}
.input-lottery {
  width: 282px;
  background-color: #f6f6f6;
  padding: 10px 30px 10px 40px;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
}
.icon-btn-report {
  margin-right: 8px;
}
.svg-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.input-game.field {
  width: 270px;
  padding: 10px 10px 10px 10px;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
}
.btn-activate {
  border: none;
  border-radius: 40px;
  width: 120px;
  height: 44px;
  color: #ffffff;
  margin-right: 70px;
  background-color: #6cbe1c;
}
.activate-book,
.return-book {
  display: flex;
  justify-content: center;
}
.book-table {
  border: 1px solid black;
}
.input-game::placeholder {
  color: #002300;
  opacity: 1; /* You can adjust the opacity */
}
.btn-scanning {
  border: none;
  border-radius: 40px;
  width: 182px;
  height: 44px;
  color: #ffffff;
  margin-right: 10px;
  background-color: #4545db;
}
.count-ticket {
  align-items: center;
  margin-top: 100px;
  font-size: 18px;
  color: #002300;
  font-weight: 600;
}
.house-charge-container {
  width: 755px;
  height: 315px;
  margin: 0 auto;
  display: flex;
  justify-content: center; /* Center content horizontally */
  position: relative; /* Set positioning context for absolute positioning */
}
.house-charge-title {
  position: absolute; /* Position it relative to the container */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  font-size: 22px; /* Set font size */
  font-weight: 600; /* Semi-bold */
  margin: 0; /* Remove default margin */
}
.house-charge-header {
  background-color: #f4f4f4;
  height: 55px;
  width: 100%; /* Make it full width of the container */
  border-top-left-radius: 30px; /* Adjust to match your requirement */
  border-top-right-radius: 30px; /* Adjust to match your requirement */
  position: absolute; /* Position it at the top */
  top: 50px; /* Adjust to position it below the title */
  left: 0; /* Align to the left */
}
/*=====================================================================Cash Drop===================================================================*/
.cash-drop-container {
  border-radius: 30px;
  padding: 20px;
  width: 891px;
}
.cash-drop {
  background-color: #f4f4f4;
  height: 55px;
  width: 891px;
}






.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Make sure it's above other content */
}

.loader img {
  width: 50px; /* Adjust size as needed */
  height: 50px;
}
.no-underline {
  text-decoration: none !important;
}
