.stepper-container {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  margin-top: 20px;
  width: 100%; /* Ensure the container takes full width to allow centering */
}
.step-line {
  height: 5px; /* Set height of the line */
  background-color: #e0e0e0; /* Default color */
  border-radius: 4px; /* Rounded corners */
  width: 100px; /* Fixed width of the step line */
  transition: background-color 0.3s, width 0.3s; /* Smooth transitions for color and width changes */
}
.step-line.active {
  background-color: #4545db; /* Color when active */
  width: 100px; /* Ensure width remains consistent */
}
.step-line:not(:last-child) {
  margin-right: 10px; /* Space between step lines */
}
